<template>
  <div>
    <div id="panel" v-if="isPresale">
      <div v-if="isPublic" class="flex flex-col space-y-2 text-white min-w-max">
        <button
          id="btn-mint"
          :class="[connectionStyle, mintQuantityShakiness]"
          class="
            btn
            text-4xl
            sm:text-6xl
            md:text-7xl
            lg:text-8xl
            xl:text-9xl
            btn-blue
          "
          @click="publicMintOrConnect"
        >
          {{ mintButtonText }}
        </button>
        <div
          v-if="isConnected"
          class="
            flex flex-row
            space-x-2
            text-white text-2xl
            sm:text-4xl
            md:text-5xl
            lg:text-6xl
            xl:text-7xl
          "
        >
          <button
            id="btn-inc"
            :class="connectionStyle"
            class="btn"
            @click="inc()"
          >
            +
          </button>
          <button
            id="btn-1"
            :class="connectionStyle"
            class="btn"
            @click="inc(1)"
          >
            1
          </button>
          <button
            id="btn-2"
            :class="connectionStyle"
            class="btn"
            @click="inc(2)"
          >
            2
          </button>
          <button
            id="btn-10"
            :class="connectionStyle"
            class="btn"
            @click="inc(10)"
          >
            10
          </button>
          <button
            id="btn-max"
            :class="connectionStyle"
            class="btn flex-grow"
            @click="inc(20)"
          >
            MAX
          </button>
        </div>
      </div>
      <div v-else class="flex flex-col gap-4">
        <div
          v-if="isConnected"
          class="flex flex-col gap-4 text-6xl md:text-8xl lg:text-9xl"
        >
          <button
            id="btn-premint-2"
            :class="connectionStyle"
            class="btn btn-blue animate-shake-high"
            @click="premint(2)"
          >
            PRE-MINT 2
          </button>
          <button
            id="btn-premint-1"
            :class="connectionStyle"
            class="btn btn-blue animate-shake-low"
            @click="premint(1)"
          >
            PRE-MINT 1
          </button>
        </div>
        <div v-else class="flex flex-col gap-4">
          <button
            id="btn-connect"
            :class="connectionStyle"
            class="btn text-7xl md:text-8xl lg:text-9xl btn-blue"
            @click="connect"
          >
            CONNECT
          </button>
        </div>
        <div class="font-Countdown bg-purple-900 p-4 text-xl flex flex-row">
          <div class="flex-grow">PUBLIC MINT</div>
          <div :class="timeShakiness">{{ this.countdownToPublic }}</div>
        </div>
      </div>
    </div>
    <div
      id="countdown"
      v-else
      class="
        text-white text-4xl
        sm:text-6xl
        md:text-7xl
        lg:text-8xl
        xl:text-9xl
        font-Countdown
        flex flex-col
        gap-4
      "
    >
      <div class="bg-purple-900 p-4">PRESALE</div>
      <div :class="timeShakiness" class="bg-purple-900 p-4">
        {{ this.countdownToPresale }}
      </div>
    </div>
  </div>
</template>

<script>
import TruffleContract from "@truffle/contract";
import { toChecksumAddress } from "ethereum-checksum-address";

const TWENTY_FOUR_HOURS_MS = 86400000;

export default {
  name: "MintPanel",
  props: {
    label: String,
  },
  data: function () {
    return {
      eth: this.$root.$data.eth,
      amount: 1,
      contract: {},
      contractAddresses: {},
      releaseDateMs: NaN,
      currentTime: Date.now(),
    };
  },
  created: async function () {
    let [{ live, rinkeby, release_date }, abi] = await Promise.all([
      fetch("https://contracts.nekocore.io").then((r) => r.json()),
      fetch("https://contracts.nekocore.io/abi").then((r) => r.json()),
    ]);

    this.contract = TruffleContract(abi);
    this.contractAddresses = {
      live,
      rinkeby,
    };

    // countdown. we include a 10s buffer to allow time for CloudFlare's KV data to propagate
    const msBufferTime = 10000;
    this.releaseDateMs = parseInt(release_date, 10) + msBufferTime;
    window.setInterval(() => {
      this.currentTime = Date.now();
    }, 10);
  },
  computed: {
    connectionStyle: function () {
      if (this.isConnected) {
        return this.eth.chainId === "0x1" ? "btn-live" : "btn-testnet";
      } else {
        return "btn-blue";
      }
    },
    timeShakiness: function () {
      let t;

      if (!this.isPresale) {
        t = (this.releaseDateMs - this.currentTime) / TWENTY_FOUR_HOURS_MS;
      } else {
        t = (this.releaseDateMs + TWENTY_FOUR_HOURS_MS - this.currentTime) / TWENTY_FOUR_HOURS_MS;
      }

      if (t < 0.0007) {
        // 1minute/24hours, the final minute
        return "animate-shake-intense";
      } else if (t < 0.01) {
        return "animate-shake-high";
      } else if (t < 0.1) {
        return "animate-shake-mid";
      } else if (t < 0.7) {
        return "animate-shake-low";
      } else {
        return "";
      }
    },
    mintQuantityShakiness: function() {
      if (this.amount === 20) {
        return "animate-shake-intense";
      } else if (this.amount >= 10) {
        return "animate-shake-high";
      } else if (this.amount >= 2) {
        return "animate-shake-mid";
      } else {
        return "animate-shake-low";
      }
    },
    countdownToPresale: function () {
      return this.countdownString(this.releaseDateMs, this.currentTime);
    },
    countdownToPublic: function () {
      return this.countdownString(
        this.releaseDateMs + TWENTY_FOUR_HOURS_MS,
        this.currentTime
      );
    },
    isPresale: function () {
      return (
        !isNaN(this.releaseDateMs) && this.releaseDateMs - this.currentTime < 0
      );
    },
    isPublic: function () {
      return (
        !isNaN(this.releaseDateMs) &&
        this.releaseDateMs + TWENTY_FOUR_HOURS_MS - this.currentTime < 0
      );
    },
    isConnected: function () {
      return this.eth && this.eth.selectedAddress;
    },
    mintButtonText: function () {
      if (this.isConnected) {
        return `${this.label} ${this.amount}`;
      } else {
        return "Connect";
      }
    },
  },
  methods: {
    countdownString: function (target, current) {
      if (isNaN(target)) {
        return `COMING SOON`;
      }
      let remaining = target - current;
      if (remaining > 0) {
        remaining = remaining / 1000; // truncate
        let seconds = (remaining % 60).toFixed(2).toString().padStart(5, "0");
        remaining = Math.floor(remaining / 60);
        let minutes = (remaining % 60).toString().padStart(2, "0");
        remaining = Math.floor(remaining / 60);
        let hours = (remaining % 24).toString().padStart(2, "0");
        let days = Math.floor(remaining / 24)
          .toString()
          .padStart(2, "0");
        return `${days}:${hours}:${minutes}:${seconds}`;
      }
      return `00:00:00:00.00`;
    },
    inc: function (n) {
      if (n) {
        this.amount = n;
      } else {
        this.amount += 1;
      }
      this.amount = Math.min(this.amount, 20);
    },
    publicMintOrConnect: async function () {
      if (this.eth) {
        if (this.eth.selectedAddress) {
          this.mint();
        } else {
          this.eth
            .request({
              method: "eth_requestAccounts",
            })
            .catch((err) => console.log(err));
        }
      } else {
        window.open("https://metamask.io/");
      }
    },
    connect: async function () {
      // this function is nearly identical to the one above it
      // and is set up this way simply because launch week is
      // exhausting, please forgive
      if (this.eth) {
        if (this.eth.selectedAddress === null) {
          this.eth
            .request({
              method: "eth_requestAccounts",
            })
            .catch((err) => console.log(err));
        }
      } else {
        window.open("https://metamask.io/");
      }
    },
    mint: async function () {
      this.contract.setProvider(this.eth);
      let { live, rinkeby } = this.contractAddresses;
      let address = this.eth.chainId === "0x1" ? live : rinkeby;
      let click_url =
        this.eth.chainId === "0x1" ? "etherscan.io" : "rinkeby.etherscan.io";
      let instance = await this.contract.at(address);
      let pendingToast = this.$toast.open({
        type: "info",
        position: "bottom",
        duration: 0, // don't time out
        dismissible: false,
        message: `Recruiting ${this.amount} NEKOCORE...`,
      });
      let success = (e) => {
        pendingToast.dismiss();
        this.$toast.open({
          type: "success",
          position: "bottom",
          duration: 0,
          message: `Successfully recruited ${this.amount} NEKOCORE! Click here to view transaction!`,
          onClick: () => {
            window.open(`https://${click_url}/tx/${e.tx}`);
          },
        });
      };
      let failure = () => {
        pendingToast.dismiss();
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: `Failed to mint!`,
        });
      };
      // ^^^ common bits ----------------------------------------------------
      let [cost, isMintable] = await Promise.all([
        instance.PRICE_PER_TOKEN(),
        instance.MINTABLE.call(),
      ]);

      // client side checks because you can't rely on metamask to bubble up errors properly
      // ------------------------------------------------------------------------------------
      if (!isMintable) {
        pendingToast.dismiss();
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: `Contract is not currently mintable!`,
        });
        return;
      }
      // ------------------------------------------------------------------------------------
      let estimate;
      try {
        estimate = await instance.mint.estimateGas(this.amount, {
          from: this.eth.selectedAddress,
          value: cost * this.amount,
        });
      } catch (e) {
        pendingToast.dismiss();
        let usefulPart = e.message.split("\n")[0];
        if (usefulPart.startsWith('err: insufficient funds for gas')) {
          usefulPart = 'insufficient funds in wallet';
        }
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: usefulPart,
        });
        return;
      }
      instance
        .mint(this.amount, {
          from: this.eth.selectedAddress,
          value: cost * this.amount,
          gas: estimate,
        })
        .then(success)
        .catch(failure);
    },
    premint: async function (count) {
      this.contract.setProvider(this.eth);
      let { live, rinkeby } = this.contractAddresses;
      let address = this.eth.chainId === "0x1" ? live : rinkeby;
      let click_url =
        this.eth.chainId === "0x1" ? "etherscan.io" : "rinkeby.etherscan.io";
      let instance = await this.contract.at(address);
      let pendingToast = this.$toast.open({
        type: "info",
        position: "bottom",
        duration: 0, // don't time out
        dismissible: false,
        message: `Recruiting ${count} NEKOCORE...`,
      });
      let success = (e) => {
        pendingToast.dismiss();
        this.$toast.open({
          type: "success",
          position: "bottom",
          duration: 0,
          message: `Successfully recruited ${count} NEKOCORE! Click here to view transaction!`,
          onClick: () => {
            window.open(`https://${click_url}/tx/${e.tx}`);
          },
        });
      };
      let failure = () => {
        pendingToast.dismiss();
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: `Failed to mint!`,
        });
      };
      // ^^^ common bits ----------------------------------------------------

      let checksum = toChecksumAddress(this.eth.selectedAddress);
      let [cost, isPresale, isMintable, current, proof, wlroot, wlstats] =
        await Promise.all([
          instance.PRICE_PER_TOKEN_PRESALE(),
          instance.MINTABLE_PRESALE.call(),
          instance.MINTABLE.call(),
          instance.premintCount(checksum),
          fetch(`https://whitelist.nekocore.io/proof/${checksum}`).then((r) =>
            r.json()
          ),
          instance.WHITELIST_ROOT.call(),
          fetch(`https://whitelist.nekocore.io/stats`).then((r) => r.json()),
        ]).catch(failure);

      // client side checks because you can't rely on metamask to bubble up errors properly
      // ------------------------------------------------------------------------------------
      if (wlstats.merkle_root !== wlroot) {
        pendingToast.dismiss();
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: `Whitelist info needs to be updated, please refresh page!`,
        });
        return;
      }
      if (!isPresale || isMintable) {
        pendingToast.dismiss();
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: `Contract is not in presale!`,
        });
        return;
      }
      if (proof.length === 0) {
        pendingToast.dismiss();
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: `${checksum} not found on whitelist!`,
        });
        return;
      }
      if (current.addn(count).gtn(2)) {
        pendingToast.dismiss();
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: `${checksum} is not allowed to mint that many!`,
        });
        return;
      }
      // ------------------------------------------------------------------------------------
      let estimate;
      try {
        estimate = await instance.mintPresale.estimateGas(count, proof, {
          from: this.eth.selectedAddress,
          value: cost * count,
        });
      } catch (e) {
        pendingToast.dismiss();
        let usefulPart = e.message.split("\n")[0];
        if (usefulPart.startsWith('err: insufficient funds for gas')) {
          usefulPart = 'insufficient funds in wallet';
        }
        this.$toast.open({
          type: "error",
          position: "bottom",
          message: usefulPart,
        });
        return;
      }
      instance
        .mintPresale(count, proof, {
          from: this.eth.selectedAddress,
          value: cost * count,
          gas: estimate,
        })
        .then(success, failure);
    },
  },
};
</script>
